import React, { useState, useEffect } from "react"
import Recaptcha from "react-recaptcha"
import {
  Flex,
  Input,
  Grid,
  Label,
  Box,
  Button,
  Heading,
  Text,
  Select,
  Form,
  Textarea
} from "theme-ui"

const ContactForm = ({ selection }) => {
  const [token, setToken] = useState(false)
  const [sent, setSent] = useState(false)
  const [selectionState, setSelectionState] = useState("schedule")

  const handleSubmit = (event) => {
    event.preventDefault();

     // Check if the honeypot field is empty
  
  
    const myForm = event.target;
    const formData = new FormData(myForm);
    console.log("does this even run?")
    console.log(formData)

    if (formData) {
      const honeypot = formData.get('website')
      if (honeypot) {
        console.error("Honeypot field filled. Possible spam detected.");
        return; // Stop form submission to prevent spam
      }
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
    .then(() => {
      console.log("Form submitted successfully");
      setSent(true)
    })
    .catch((error) => {
      console.error("Form submission error:", error);
      alert("Form submission error. Please try again later.");
    });
  };
  


  useEffect(() => {
    setSelectionState(selection)
  }, [selection])

  const handleNameChange = (event) => {
    setSelectionState(event.target.value);
  };

  return (
    <div>
          <Box
            as={'form'}
            method="POST"
            variant="layout.form"
            data-netlify-honeypot="website"
            data-netlify="true"
            name="contact"
            onSubmit={handleSubmit}
          >
            <Input type="hidden" name="website" />
            <Input type="hidden" name="form-name" value="contact" />
            {!sent ? (
              <>
                <Heading variant="mediumAlwaysDark" className="soft-faux-font">
                  We’re social animals!
                </Heading>
                <Text as="p" variant="paragraphAlwaysWhite">
                  I want to connect with you! Use the form below to reach out—to
                  inquire about appointments and events, ask questions, share
                  your comments. We survive and thrive through connection!
                </Text>
                <Grid rows={[1]} columns={[1, 3]} gap={[3]}>
                  <Box>
                    <Label htmlFor="name">Name*</Label>
                    <Input
                      variant="input.base"
                      type="text"
                      name="name"
                      id="name"
                      required
                    />
                  </Box>
                  <Box>
                    <Label htmlFor="phone">Phone*</Label>
                    <Input
                      variant="input.base"
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="(555)555-5555"
                      required
                    />
                  </Box>
                  <Box>
                    <Label htmlFor="email">Email*</Label>
                    <Input
                      variant="input.base"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="john@weirevolving.com"
                      required
                    />
                  </Box>
                </Grid>

                <Grid
                  variant="grids.formRow"
                  rows={[1]}
                  columns={[1]}
                  gap={[3]}
                >
                  <Box>
                    <Label htmlFor="email">Reason for Contacting*</Label>
                    <Select
                      value={selectionState}
                      variant="input.base"
                      type="text"
                      name="reason"
                      id="reason"
                      onChange={handleNameChange}
                      required
                    >
                      <option value="schedule">Schedule Appointment</option>
                      <option value="order">Book/Product Order</option>
                      <option value="retreats">Retreats</option>
                      <option value="inquiry">General Inquiry</option>
                      <option value="other">Other</option>
                    </Select>
                  </Box>
                </Grid>
                <Grid
                  variant="grids.formRow"
                  rows={[1]}
                  columns={[1]}
                  gap={[3]}
                >
                  <Box>
                    <Label htmlFor="message">Message*</Label>
                    <Textarea
                      component="textarea"
                      name="message"
                      id="message"
                      rows={5}
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        backgroundColor: "hsl(0,0%,100%)",
                        borderColor: "hsl(0deg 0% 80% / 47%)",
                        border: "1px solid rgba(35,33,41,0.1)",
                        color: "hsl(10, 20%, 20%)",
                        transition: "all 250ms cubic-bezier(0.4,0,0.2,1)",
                        marginBottom: "1em",
                      }}
                      required
                    />
                  </Box>
                </Grid>
                <Grid
                  gap={[2, 2, 3, 2]}
                  row={[1]}
                  columns={1}
                  sx={{ marginTop: ["1em"] }}
                >
                  <Button
                    variant="buttons.base"
                    type='submit'
                    sx={{ justifySelf: "flex-end" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Heading sx={{ fontSize: '32px'}} className="soft-faux-font">
                  Thank you for sending that in
                </Heading>
                <Text as="p" variant="paragraphAlwaysWhite">
                  Look forward to speaking with you soon!
                </Text>
              </>
            )}
          </Box>
    </div>
  )
}

export default ContactForm
