import React from "react"
import Layout from "../components/layout"
import Form from "../components/inputs/form"
import "./contact.css"
import { StaticImage } from "gatsby-plugin-image"

import { Flex, Grid, Box, Container } from "theme-ui"

export const Head = () => (
  <>
    <title>Contact</title>
    <meta name="description" content="Contact Page" />
  </>
)

const Contact = ({ location: { state } }) => {
  const option = state ? state.option : null
  console.log(state)
  return (
    <Layout>
      <Container variant="layout.top">
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: -3,
            height: "100%",
          }}
        >
          <div
            style={{
              position: "fixed",
              overflow: "none",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "grid",
                width: "100%",
                height: "100vh",
                backgroundColor: "blue",
              }}
            >
              <StaticImage
                style={{
                  gridArea: "1/1",
                  // You can set a maximum height for the image, if you wish.
                  // maxHeight: 600,
                }}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectRatio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                src="../images/contact/camp.jpg"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <Box className="wrapper">
              <div className="flame-wrapper">
                <div className="flame red"></div>
                <div className="flame orange"></div>
                <div className="flame gold"></div>
                <div className="flame white"></div>
              </div>
              <div className="spark spark1"></div>
              <div className="spark spark2"></div>
              <div className="spark spark3"></div>
              <div className="spark spark4"></div>
              <div className="spark spark5"></div>
            </Box>
          </div>
        </div>
        <Grid
          gap={[2, 2, 3, 3]}
          row={[2, 2, 1, 1]}
          columns={[1, 1, 1, "3fr 9fr"]}
          sx={{ height: "90%" }}
        >
          <div></div>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: ["auto", "100vh"],
              margin: ["1em 0", "1em 2em"],
            }}
          >
            <Flex
              sx={{
                margin: "3em auto",
                width: ["90%", "650px"],
                flexDirection: "column",
                alignSelf: "center",
                backgroundColor: "#130b2e",
                borderRadius: "5px",
                padding: "2em",
                justifySelf: "center",
                color: "white",
                "@media only screen and (max-width: 500px)": {
                  margin: "0em auto",
                  padding: "1em 2em",
                },
              }}
            >
              <Form selection={option} />
            </Flex>
          </Flex>
        </Grid>
      </Container>
    </Layout>
  )
}
export default Contact
